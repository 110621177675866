<template>
  <BOverlay
    :show="spinner"
    spinner-variant="primary"
    variant="semi-dark"
  >
    <BCard>
      <BRow>

        <!-- SELECT CURSO -->
        <BCol
          cols="12"
          md="4"
          class="'mb-50"
        >
          <v-select
            v-if="optionsCursos.length > 1"
            input-id="id_curso"
            v-model="id_curso"
            placeholder="Filtrar por curso..."
            label="texto"
            :options="optionsCursos"
            :reduce="option => option.id"
            :clearable="false"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
          />
          <div
            v-else="optionsCursos.length > 0"
            class="text-left mt-50"
          >
            <h4 class="text-primary">
              {{
                typeof optionsCursos[0] !== 'undefined'
                  ? optionsCursos[0].texto
                  : ''
              }}
            </h4>
          </div>
        </BCol>

        <!-- BOTON CREAR -->
        <BCol
          cols="12"
          md="8"
          class="mb-50 mt-50 mt-md-0"
        >
          <btnCrear
            texto="Salida"
            modulo="salidas"
            @processAdd="addSalida"
          />
        </BCol>
      </BRow>
      <!-- TABLA -->
      <BRow>
        <BCol cols="12">
          <BTable
            striped
            small
            hover
            noCollapse
            responsive
            show-empty
            class="mt-1"
            :items="items"
            :fields="fields"
            :busy="cargando"
            @filtered="onFiltered"
          >
            <template #empty="scope">
              <div v-if="optionsCursos.length > 1" cols="12">
                <b-alert
                  :show="!id_curso"
                  variant="primary"
                >
                  <div
                    class="alert-body text-center mt-2"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-50"
                    />
                    Primero seleccione un curso<br>
                  </div>
                </b-alert>
                <b-alert
                  :show="id_curso"
                  variant="primary"
                >
                  <div
                    class="alert-body text-center mt-2"
                  >
                    <feather-icon
                      icon="InfoIcon"
                      class="mr-50"
                    />
                    El curso filtrado no tiene salidas creadas<br>
                  </div>
                </b-alert>
              </div>
            </template>
            <!-- Cargando -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <spinner />
              </div>
            </template>

            <!-- col: CURSO -->
            <template #cell(curso)="data">
              {{ data.item.nombre_grado }} {{ data.item.letra }}
            </template>

            <!-- col: FECHA -->
            <template #cell(fecha)="data">
              {{ formatoDiaMesYear(data.item.fecha) }}
            </template>

            <!-- col: ESTUDIANTE -->
            <template #cell(estudiante)="data">
              {{ data.item.rut }}-{{ data.item.dv }} {{ data.item.nombre }} {{ data.item.primer_apellido }}
            </template>

            <!-- col: HORA SALIDA -->
            <template #cell(hora_salida)="data">
              {{ formatHoraVer(data.item.hora_salida) }}
            </template>

            <!-- col: HORA REGRESO -->
            <template #cell(hora_regreso)="data">
              {{ formatHoraVer(data.item.hora_regreso) }}
            </template>

            <!-- col: Action -->
            <template #cell(acciones)="data">
              <col-acciones-btnes
                modulo="salidas"
                :data="data"
                @processGoToUpdate="goToUpdate"
                @processRemove="remove(data.item)"
              />
            </template>
          </BTable>
        </BCol>

      </BRow>
    </BCard>
  </BOverlay>
</template>

<script>
import {
  BTable, BRow, BCol, BPagination, BFormCheckbox, BOverlay, BCard, BFormGroup,
  BAlert,
} from 'bootstrap-vue'

// FORMATOS
import { formatos } from '@/mixins/formatos'

import vSelect from 'vue-select'
import { mapGetters, mapActions, mapMutations } from 'vuex'

// COMPONENTES RECICLADOS
import btnCrear from '../components/List/btnCrear.vue'
import colAccionesBtnes from '../components/List/colAccionesBtnes.vue'
import spinner from '../components/spinner.vue'

export default {
  components: {
    BTable,
    BRow,
    BCol,
    BPagination,
    BFormCheckbox,
    BOverlay,
    BCard,
    BFormGroup,
    vSelect,
    BAlert,

    // COMPONENTES RECICLADOS
    btnCrear,
    spinner,
    colAccionesBtnes,
  },
  mixins: [formatos],
  data() {
    return {
      cargando: false,
      spinner: false,
      items: [],
      optionsCursos: [],
      id_curso: null,

      perPage: 25,
      totalRows: 1,
      currentPage: 1,
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: '',
      filterOn: [],
      pageOptions: [10, 25, 50],
      infoModal: {
        id: 'info-modal',
        title: '',
        content: '',
      },
      fields: [
        {
          key: 'curso',
          label: 'Curso',
          sortable: true,
          thStyle: {
            width: '20% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'fecha',
          label: 'Fecha',
          sortable: true,
          thStyle: {
            width: '20% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'estudiante',
          label: 'Estudiante',
          sortable: false,
          thStyle: {
            width: '40% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'hora_salida',
          label: 'Hora Salida',
          sortable: true,
          thStyle: {
            width: '10% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
        {
          key: 'hora_regreso',
          label: 'Hora Regreso',
          sortable: true,
          thStyle: {
            width: '10% !important',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
      fieldAcciones: [
        {
          key: 'acciones',
          label: 'acciones',
          tdClass: 'text-center',
          thStyle: {
            width: '10% !important',
            'text-align': 'center',
            display: 'table-cell',
            'vertical-align': 'middle',
          },
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getUser: 'auth/user',
      getCursos: 'cursos/getCursos',
      getSalidas: 'salidas/getSalidas',
    }),
  },
  watch: {
    getSalidas(val) {
      this.totalRows = val.length
      this.items = []
      this.items = this.getSalidas
    },
    id_curso(id_curso) {
      },
  },
  mounted() {
    this.cargarSalida(this.getUser.id_establecimiento)
    this.setTableList()
  },
  methods: {
    ...mapActions({
      fetchSalidas: 'salidas/fetchSalidas',
      removeSalida: 'salidas/removeSalida',
    }),
    ...mapMutations('salidas', ['setSalidaSelected']),
    setTableList() {
      if (this.$can('update', 'salidas')
        || this.$can('delete', 'salidas')
      ) {
        this.fields.push(this.fieldAcciones)
      }
    },
    cargarSalida(id_establecimiento) {
      this.cargando = true
      this.fetchSalidas(id_establecimiento).then(() => {
        this.cargando = false
      })
    },
    setCursosJefatura() {
      this.optionsCursos = []
      this.getCursos.forEach(curso => {
        const texto = `${curso.nombre} ${curso.letra}`
        this.optionsCursos.push({
          id: curso.id,
          texto,
        })
      })
      if (this.getCursos.length === 1) {
        this.id_curso = this.getCursos[0].id
      }
    },
    addSalida() {
      this.$router.replace({
        name: 'salida-create',
      })
    },
    goToUpdate(salida) {
      this.setSalidaSelected(salida)
      this.$router.push({
        name: 'salida-update',
      })
    },
    remove(salida) {
      this.$swal({
        title: 'Eliminar salida!',
        text: `Estás seguro que deseas eliminar la salida?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Si, eliminala!',
        cancelButtonText: 'Cancelar',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        this.spinner = true
        if (result.value) {
          const data = {
            id: salida.id,
            id_establecimiento: this.getUser.id_establecimiento
          }
          this.removeSalida(data).then(() => {
            this.$swal({
              icon: 'success',
              title: 'Eliminada con éxito!',
              text: `La salida, ha sido eliminada!`,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
            this.spinner = false
          })
        } else {
          this.spinner = false
        }
      })
    },

    // Vuexy
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`
      this.infoModal.content = JSON.stringify(item, null, 2)
      this.$root.$emit('bv::show::modal', this.infoModal.id, button)
    },
    resetInfoModal() {
      this.infoModal.title = ''
      this.infoModal.content = ''
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of
      // buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
</style>
